import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import userService from "../services/UserService";
import MyButton from "../UI/button/MyButton";
import Overlay from "./Overlay";

const Notification = () => {
  const { authUser, notifications, loading } = useAuth();
  const [showNotifications, setShowNotifications] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      setShowNotifications(true);
      setCurrentIndex(0);
    }
  }, [notifications]);

  if (loading || !authUser || notifications == null || notifications.length === 0) {
    return null;
  }

  const handleNextNotification = async () => {
    if (currentIndex < notifications.length - 1) {
      await userService.setNotificationRead(notifications[currentIndex].id);
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      await userService.setNotificationRead(notifications[currentIndex].id);
      setShowNotifications(false);
    }
  };

  return (
    <>
      {authUser && showNotifications && (
        <Overlay
          body={
            <div className="notification-layout">
            <div className="notification-container">
              <h3>Notificare</h3>
              <div>
                <h4>{notifications[currentIndex].title}</h4>
                <div dangerouslySetInnerHTML={{ __html: notifications[currentIndex].message }} />
              </div>
            </div>
            <MyButton 
                onClick={handleNextNotification} 
                body={currentIndex < notifications.length - 1 ? "Următoarea" : "Închide"} 
                type="button" 
              />
            </div>
          }
          isOpen={showNotifications}
          handleOverlay={() => setShowNotifications(false)}
        />
      )}
    </>
  );
};

export default Notification;
