import instance from "../interceptors/AxiosInterceptor";

const BASE_URL = "/api/user";
const userService = {

    getCurrentUser: async () => {
        try {
            const response = await instance.get(`${BASE_URL}/details`, {
                withCredentials: true
            });
            return response.data;  
        } catch (error) {
            throw error;
        }
    },

    updateUserDetails: async (name, telephoneNumber, verificationCode) => {
        const requestBody = {};
        if (telephoneNumber) {
            requestBody.telephoneNumber = telephoneNumber;
        }
        if (name) {
            requestBody.name = name;
        }
        try {
            const response = await instance.put(
                `${BASE_URL}/update-details`,
                requestBody,
                {
                    params: { verificationCode },  
                    withCredentials: true
                }
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    
    changeUserPassword: async (currentPassword, newPassword, verificationCode) => {
        try {
            const response = await instance.post(`${BASE_URL}/change-password`, {
                oldPassword: currentPassword,
                newPassword: newPassword
            }, {
                params: { verificationCode },  
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    
    changeUserEmail: async (newEmail, verificationCode) => {
        try {
            const response = await instance.post(`${BASE_URL}/change-email`, {
                email: newEmail,
                verificationToken: verificationCode
            }, {
                withCredentials: true
            });
    
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getUserCalcs: async () => {
        try{
            const response = await instance.get(`${BASE_URL}/calculations`,{
                withCredentials:true
            });
            return response.data;
        }catch(error){
            throw error;
        }
    },

    linkWithCustomPassword: async (newPassword) =>{
        try{
            const response = await instance.post(`${BASE_URL}/linkOAuthWithCustomPassword`,{
                newPassword
            },{
                withCredentials: true
            });
            return response.data;
            
        }catch(error){
            throw error;
        }
    },
    updateNewlyCreatedDetails: async (personType,telephoneNumber,IDNO) => {
        const requestBody = {
            personType: personType,
            telephoneNumber: telephoneNumber
        }
        if (personType) {
            requestBody.IDNO = IDNO;
        }
        try {
            const response = await instance.put(`${BASE_URL}/update-newly-created-details`,requestBody,
            {
            withCredentials: true});
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    acceptTerms: async () =>{
        try {
            const response = await instance.get(`${BASE_URL}/accept-terms`,{
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    confirmSubUser: async (tokenValue) => {
        try {
            const response = await instance.post(`${BASE_URL}/confirmSubUser?tokenValue=${tokenValue}`,
            {
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    deleteSubUser: async (subUserId) => {
        try {
            const response = await instance.post(`${BASE_URL}/deleteSubUser?subUserId=${subUserId}`,{
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    cancelSubUser: async () => {
        try {
            const response = await instance.post(`${BASE_URL}/cancelSubUser`,{
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    addSubUser: async (emailList) => {
        try {
            const response = await instance.post(`${BASE_URL}/addSubUser`,
                emailList
            ,{
                withCredentials: true
            });
        } catch (error) {
            throw error;
        }
    },
    setNotificationRead: async (notificationId) => {
        try {
            const response = await instance.post(`${BASE_URL}/notifications/${notificationId}/read`,
            {withCredentials: true});
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    
    
}

export default userService;