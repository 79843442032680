import React from "react";
import './assets/css/App.css';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { AuthProvider } from "./context/AuthContext";
import AppRouter from "./router/AppRouter";
import { ResultsProvider } from "./context/ResultsContext";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import { CalculatorDataProvider } from "./context/CalculatorDataContext";
import { CheckoutOverlayProvider } from "./context/CheckoutContext";
import { PaymentMethodProvider } from "./context/PaymentMethodContext";
import { NavLink } from "react-router-dom";
import { CalcsProvider } from "./context/CalcsContext";
import Notification from "./components/Notification";
function App() {

  return (
    <AuthProvider>
      <CalcsProvider>
      <PaymentMethodProvider>
      <CheckoutOverlayProvider>
      <CalculatorDataProvider>
            <ResultsProvider>
      <div className="App">
        <Navbar />
        <div className="main-content">
          <div className="mobile-nav hide-on-print">
            <span><NavLink to={"/penalitate"} className="navbar-link">Penalitate</NavLink></span>
            <span><NavLink to={"/dobanda"} className="navbar-link">Dobanda</NavLink></span>
            <span><NavLink to={"/inflatie"} className="navbar-link">Inflatie</NavLink></span>
            <span><NavLink to={"/zile"} className="navbar-link">Zile</NavLink></span>
            <span><a href="https://taxa.prolex.md" className="navbar-link" target="_blank">Taxa</a></span>
          </div>
              <AppRouter />
        </div>
        <Footer />
      </div>
      </ResultsProvider>
          </CalculatorDataProvider>
      </CheckoutOverlayProvider>
      </PaymentMethodProvider>
      </CalcsProvider>
    <ToastContainer
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
    <Notification/>
    </AuthProvider>
  );
}

export default App;
