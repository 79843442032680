import instance from "../interceptors/AxiosInterceptor";

const BASE_URL = "/api/calculator";

const calculatorService = {

    getPenaltiesResults: async (body) => {
        try {
            const response = await instance.post(`${BASE_URL}/penaltiesCalculate`, body, {
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    
    getInterestRateResults: async (body) => {
        try {
            const response = await instance.post(`${BASE_URL}/interestRateCalculate`, body, {
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    
    getInflationResults: async (body) => {
        try {
            const response = await instance.post(`${BASE_URL}/inflationCalculate`, body, {
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getCombinedResults: async (body)=>{
        try {
            const response = await instance.post(`${BASE_URL}/combinedCalculate`, body, {
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    
    getCalculation: async (calcId) => {
        try{
            const response = await instance.post(`${BASE_URL}/getCalculation?calcId=${calcId}`,
            null,
            {
                withCredentials:true,
            });
            return response.data;
        }catch(error){
            throw error;
        }
    },

    renameCalculation: async (newName, calcId) => {
        try{
            const response = await instance.post(`${BASE_URL}/renameCalculation`,{
                newName: newName,
                calcId: calcId
            },{
                withCredentials:true
            })
            return response.data;
        }catch(error){
            throw error;
        }
    },
    
    deleteCalculation: async (calcId) => {
        try {
            const response = await instance.post(
                `${BASE_URL}/deleteCalculation?calcId=${calcId}`,
                null,
                {
                    withCredentials: true,
                }
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    saveCalculation: async (calcId) => {
        try {
            const response = await instance.post(
                `${BASE_URL}/saveCalculation?calcId=${calcId}`,
                null,
                {
                    withCredentials: true,
                }
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    generatePdf: async (url) => {
        try {
            const response = await instance.post(
                `${BASE_URL}/generate`,
                { url },
                {
                    withCredentials: true,
                    responseType: 'blob', 
                }
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    
    

}

export default calculatorService