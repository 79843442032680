import React from 'react';
import { FaEdit } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { valute } from '../assets/data/option';
import { useAuth } from '../context/AuthContext';
import { useCalculatorData } from '../context/CalculatorDataContext';
import { useCheckoutOverlay } from '../context/CheckoutContext';
import { useScreenWidth } from '../hooks/useScreenWidth';
import InterestRateFormula from './InterestRateFormula';

export default function ResultsTable({ data, paid,calcName,handleTitleInputOverlay, calcAdresare}) {
  const { authUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const {valuta} = useCalculatorData();
  const selectedValue = valute.find(option => option.value === valuta) || null;
  const isMobileWidth = useScreenWidth();
  const { handleCheckoutOverlay } = useCheckoutOverlay();
  if (!authUser) {
    localStorage.setItem('prevPath', location.pathname + location.search);
  }
  const handleRowClick = () => {
    navigate('/login');
  };
  const handlePaymentClick = () => {
    handleCheckoutOverlay();
  }
  const handleEditClick = async () => {
    handleTitleInputOverlay();
  };
  if(data.penaltiesResults){
    const { penaltiesResults, totalPenalty, totalPaid, totalScadent, totalDuty,daysTillStart, brief,penaltyType} = data;
    return (
      <div className='results-content' >
      <div className='table-header'>
      <div className='info'>
      <div className='inline-wrapper'>
      <b>{calcName}</b>
      <FaEdit className='edit-icon hide-on-print' onClick={handleEditClick}/>
      </div>
      <div className='inline-wrapper'>
      <b>Calculul penalității</b>
      </div>
      <p>
      <b>Penalitatea</b> constituie – <b style={!paid ?{ filter: 'blur(5px)' } : null}>{totalPenalty.toFixed(2)} {selectedValue.label}</b> şi a fost calculată pentru perioada 
      <b>
  &nbsp;
  {data.penaltyStartDate &&
    new Date(data.penaltyStartDate)
      .toLocaleDateString('en-GB')
      .replace(/\//g, '.')} –{' '}
  {data.penaltyDate &&
    new Date(data.penaltyDate)
      .toLocaleDateString('en-GB')
      .replace(/\//g, '.')}
</b>
            </p>
        </div>
</div>
        <table id="results-table" className='table'>
        <thead>
          <tr>
            <th>Zi</th>
            <th>Data</th>
            <th>Sume scadente</th>
            <th>Sume achitate</th>
            <th>Datorie</th>
            <th>Datorie conform contractului</th>
            {brief ? <th>Numărul de zile</th> : null}
            <th>Mărime penalitate</th>
            <th>Penalitate</th>
            <th>Penalitate total</th>
          </tr>
        </thead>
        <tbody>
  {(authUser && paid) ? penaltiesResults.flatMap(item => {
    const rows = [];
    const movedRowCount = ((item.scadentValue !== 0 || item.payValue !== 0) && (item.moved && daysTillStart !== 0) ? 1 : 0) + ((item.scadentValue !== 0 || item.payValue !== 0) && (data.carantine && (item.quarantineEnd || item.quarantineStart)) ? 1 : 0);
    const mainRow = (
      <tr key={item.day}>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.date < data.penaltyStartDate ? '': item.day}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{new Date(item.date).toLocaleDateString('en-GB').replace(/\//g, '.')}</td>
        {item.scadentValue !== 0 || item.payValue !== 0 ?
      <>
      <td className={item.scadentValue === 0 && item.payValue === 0 ? "scadent-noborder" : ""}>{item.scadentValue !== 0 ? item.scadentValue.toFixed(2) : ""}</td>
      <td className={item.payValue === 0 && item.scadentValue === 0 ? "paied-noborder" : ""}>{item.payValue !== 0 ? item.payValue.toFixed(2) : ""}</td></> 
      : 
      <>
      <td colSpan ="2" className={data.carantine && item.quarantineStart || item.quarantineEnd ? 'moved quarantine': ''}>
        {data.carantine ? item.quarantineStart ? <>Inceput stare de urgență <br /></> : "" : ""}
        {data.carantine ? item.quarantineEnd ? <>Sfarsit stare de urgență  <br/></>: "" : ""}
        {item.moved && daysTillStart !== 0 ? <> {`${daysTillStart} zile (${item.movedValue.toFixed(2)})`} <br /></> : ""}
      </td>
      </>
      }
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.duty !== 0 ? item.duty.toFixed(2) : ""}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.contractDuty !== 0 ? item.contractDuty.toFixed(2) : ""}</td>
        {brief ? <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.contractDuty === 0 ? "" : item.daysNumber == 0 ? "": item.daysNumber}</td>:null}
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.contractDuty === 0 ? "" : penaltyType ? item.penaltyValue !== 0 ? item.penaltyValue.toFixed(2) : "" : item.penaltyValue !== 0 ? (item.penaltyValue * 100).toFixed(2) + "%" : ""}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.penaltyPerDay !== 0 ? item.penaltyPerDay.toFixed(2) : ""}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.totalPenalty !== 0 ? item.totalPenalty.toFixed(2) : ""}</td>
      </tr>
    );
    rows.push(mainRow);

    if ((item.scadentValue !== 0 || item.payValue !== 0) && item.moved && daysTillStart !== 0 ) {
      const movedRow = (
        <tr key={`${item.day}-moved`}>
          <td colSpan="2" className='moved'>{`${daysTillStart} zile (${item.movedValue.toFixed(2)})`}</td>
        </tr>
      );
      rows.push(movedRow);
    }
    if((item.scadentValue !== 0 || item.payValue !== 0) && data.carantine){
      if(item.quarantineStart){
        const movedRow = (
          <tr key={`${item.day}-moved`}>
            <td colSpan="2" className='moved quarantine'>Inceput stare de urgență</td>
          </tr>
        );
        rows.push(movedRow);
      }
      if(item.quarantineEnd){
        const movedRow = (
          <tr key={`${item.day}-moved`}>
            <td colSpan="2" className='moved quarantine'>Sfarsit stare de urgență</td>
          </tr>
        );
        rows.push(movedRow);
      }
    }

    return rows;
  }) : Array.from({ length: 1 }).map((_, index) => (
    <tr key={index} className="blurred-row" onClick={!authUser ? handleRowClick : handlePaymentClick}>
    <td colSpan={brief ? "10" : "9"} >
        {<span className="message">{authUser ? "Alege unul din abonamente pentru a vizualiza rezultatul" : "Autentifica-te pentru a vizualiza rezultatul"}</span>}
    </td>
</tr>
  ))}
   {authUser &&          <tr className='last-row'>
            <td>Total:</td>
            <td></td>
            <td>{totalScadent.toFixed(2)}</td>
            <td>{totalPaid.toFixed(2)}</td>
            <td>{totalDuty.toFixed(2)}</td>
            <td></td>
            <td></td>
            {brief ? <td></td>:null}
            <td></td>
            <td>{totalPenalty.toFixed(2)}</td>
          </tr>}
</tbody>
      </table>
      </div>
    );
  }else if(data.ratesResults){
    const { ratesResults, totalPenalty, totalPaid, totalScadent, totalDuty, daysTillStart, brief, rateValue } = data;
    return (
     <div className='results-content'>


        <div className='table-header'>
        <div className='info'>
        <div className='inline-wrapper'>
  <b>{calcName}</b>
  <FaEdit className='edit-icon hide-on-print' onClick={handleEditClick}/>
  </div>
  <b>Calculul dobânzii de întârziere</b>
            <p>
            <b>Dobânda de întârziere</b> constituie – <b style={!paid ?{ filter: 'blur(5px)' } : null}> {totalPenalty.toFixed(2)} {selectedValue.label} </b> şi a fost calculată pentru perioada 
            <b>
  &nbsp;
  {data.ratesResults[0].date &&
    new Date(data.ratesResults[0].date)
      .toLocaleDateString('en-GB')
      .replace(/\//g, '.')} –{' '}
  {data.penaltyDate &&
    new Date(data.penaltyDate)
      .toLocaleDateString('en-GB')
      .replace(/\//g, '.')}
</b>
,
            în temeiul art. 942 alin.2 din CC RM, după formula: 
            <br/>
           <p className='inline-wrapper'>
           <InterestRateFormula 
            rateValue = { rateValue } />
           </p>
            </p>
            <p>
            D – suma datoriei,<br/>
            X – rata de refinanţare a BNM,<br/>
            { rateValue * 100 }% - procentul de întârziere peste rata dobânzii, conform art.874 CC RM,<br/>
            365 – numărul de zile întru-un an,<br/>
            Y – numărul de zile de întârziere.<br/>
            </p>
            <p className="hidden-print"><a href="https://www.bnm.md/ro/content/ratele-dobanzilor?base_rates_full" target="_blank">Click aici pentru a vedea Statistica privind rata de bază şi ratele dobânzilor la instrumentele de reglementare monetară ale BNM</a></p>
        </div>
        </div>
       <table id="results-table" className='table'>
        <thead>
          <tr>
            <th>Zi</th>
            <th>Data</th>
            <th>Sume scadente</th>
            <th>Sume achitate</th>
            <th>Datorie</th>
            <th>Datorie conform contractului</th>
            {brief ? <th>Numărul de zile</th> : null}
            <th>Rata de bază</th>
            <th>Dobânda</th>
            <th>Dobânda total</th>
          </tr>
        </thead>
        <tbody>
  {(authUser && paid) ? ratesResults.flatMap(item => {
    const rows = [];
    const movedRowCount = ((item.scadentValue !== 0 || item.payValue !== 0) && (item.moved && daysTillStart !== 0) ? 1 : 0) + ((item.scadentValue !== 0 || item.payValue !== 0) && (data.carantine && (item.quarantineEnd || item.quarantineStart)) ? 1 : 0);
    const mainRow = (
      <tr key={item.day}>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.day}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{new Date(item.date).toLocaleDateString('en-GB').replace(/\//g, '.')}</td>
        {item.scadentValue !== 0 || item.payValue !== 0 ?
      <>
      <td className={item.scadentValue === 0 && item.payValue === 0 ? "scadent-noborder" : ""}>{item.scadentValue !== 0 ? item.scadentValue.toFixed(2) : ""}</td>
      <td className={item.payValue === 0 && item.scadentValue === 0 ? "paied-noborder" : ""}>{item.payValue !== 0 ? item.payValue.toFixed(2) : ""}</td></> 
      : 
      <>
      <td colSpan ="2" className={data.carantine && item.quarantineStart || item.quarantineEnd ? 'moved quarantine': ''}>
        {data.carantine ? item.quarantineStart ? <>Inceput stare de urgență <br /></> : "" : ""}
        {data.carantine ? item.quarantineEnd ? <>Sfarsit stare de urgență  <br/></>: "" : ""}
        {item.moved && daysTillStart !== 0 ? <> {`${daysTillStart} zile (${item.movedValue.toFixed(2)})`} <br /></> : ""}
      </td>
      </>
      }
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.duty !== 0 ? item.duty.toFixed(2) : ""}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.contractDuty !== 0 ? item.contractDuty.toFixed(2) : ""}</td>
        {brief ? <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.contractDuty === 0 ? "" : item.daysNumber == 0 ? "": item.daysNumber}</td>:null}
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{ item.contractDuty === 0 ? ""  :  item.baseRate !== 0 ? `${(item.baseRate.toFixed(4) * 100).toFixed(2)}%` : ""}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.interestRate !== 0 ? item.interestRate.toFixed(2) : ""}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.totalInterestRate !== 0 ? item.totalInterestRate.toFixed(2) : ""}</td>
      </tr>
    );
    rows.push(mainRow);
    if ((item.scadentValue !== 0 || item.payValue !== 0) && item.moved && daysTillStart !== 0 ) {
      const movedRow = (
        <tr key={`${item.day}-moved`}>
          <td colSpan="2" className='moved'>{`${daysTillStart} zile (${item.movedValue.toFixed(2)})`}</td>
        </tr>
      );
      rows.push(movedRow);
    }
    if((item.scadentValue !== 0 || item.payValue !== 0) && data.carantine){
      if(item.quarantineStart){
        const movedRow = (
          <tr key={`${item.day}-moved`}>
            <td colSpan="2" className='moved quarantine'>Inceput stare de urgență</td>
          </tr>
        );
        rows.push(movedRow);
      }
      if(item.quarantineEnd){
        const movedRow = (
          <tr key={`${item.day}-moved`}>
            <td colSpan="2" className='moved quarantine'>Sfarsit stare de urgență</td>
          </tr>
        );
        rows.push(movedRow);
      }
    }
    return rows;
  }) 
  : Array.from({ length: 1 }).map((_, index) => (
    <tr key={index} className="blurred-row" onClick={!authUser ? handleRowClick : handlePaymentClick}>
    <td colSpan={brief ? "10" : "9"} >
    {<span className="message">{authUser ? "Alege unul din abonamente pentru a vizualiza rezultatul" : "Autentifica-te pentru a vizualiza rezultatul"}</span>}
    </td>
</tr>
  ))}
 {authUser &&   <tr className='last-row'>
            <td>Total:</td>
            <td></td>
            <td>{totalScadent.toFixed(2)}</td>
            <td>{totalPaid.toFixed(2)}</td>
            <td>{totalDuty.toFixed(2)}</td>
            <td></td>
            {brief ? <td></td>:null}
            <td></td>
            <td></td>
            <td>{totalPenalty.toFixed(2)}</td>
          </tr>}
</tbody>
      </table>
     </div>
    );
  }else if(data.inflationsResults){
    const { inflationsResults, totalDamageAmount, totalPaid, totalScadent, totalDuty, daysTillStart, brief } = data;
    return (
     <div className='results-content'>
      
        <div className='table-header'>
        <div className='info'>
        <div className='inline-wrapper'>
  <b>{calcName}</b>
  <FaEdit className='edit-icon hide-on-print' onClick={handleEditClick}/>
  </div>
     <div className='inline-wrapper'>
     <b>Calculul inflației</b>
     </div>
            <p>
            <p>Suma prejudiciului cauzată de evoluţia indicelui preţurilor de consum (rata inflaţiei) pentru perioada 
            <b>
  &nbsp;
  {data.inflationsResults[0].date &&
    new Date(data.inflationsResults[0].date)
      .toLocaleDateString('en-GB')
      .replace(/\//g, '.')} –{' '}
  {data.penaltyDate &&
    new Date(data.penaltyDate)
      .toLocaleDateString('en-GB')
      .replace(/\//g, '.')}
</b>

            &nbsp;în conformitate cu datele oficiale ale Biroului Naţional de Statistică al RM, constituie – <b style={!paid ?{ filter: 'blur(5px)' } : null}> {data.totalDamageAmount.toFixed(2)} {selectedValue.label} </b>
            şi a fost calculată după formula:
            <p><b>Sp = Dc x Y x Z</b> , unde</p>
            <p>Sp – suma prejudiciului cauzat de evoluţia indicelui preţurilor de consum,</p>
            <p>Dc – suma datoriei creditoriale,</p>
            <p>Y – numărul de zile de întârziere,</p>
            <p>Z – rata inflaţiei/zi.</p>
            </p>
            </p>
            <p className="hidden-print"><a href="https://statbank.statistica.md/pxweb/pxweb/ro/40%20Statistica%20economica/40%20Statistica%20economica__05%20PRE__PRE010__serii%20lunare/PRE012200.px/table/tableViewLayout1/?rxid=8e69070f-3ac5-4b22-ab66-eab8ee8cc25a" target="_blank">Click aici pentru a vedea Statistica privind evoluţia indicelui preţurilor de consum (rata inflaţiei)</a></p>
        </div>
        </div>
       <table id="results-table" className='table'>
        <thead>
          <tr>
            <th>Zi</th>
            <th>Data</th>
            <th>Sume scadente</th>
            <th>Sume achitate</th>
            <th>Datorie</th>
            <th>Datorie conform contractului</th>
            {brief ? <th>Numărul de zile</th> : null}
            <th>Rata inflației</th>
            <th>Prejudiciul</th>
            <th>Prejudiciul total</th>
          </tr>
        </thead>
        <tbody>
  {(authUser && paid) ? inflationsResults.flatMap(item => {
    const rows = [];
    const movedRowCount = ((item.scadentValue !== 0 || item.payValue !== 0) && (item.moved && daysTillStart !== 0) ? 1 : 0) + ((item.scadentValue !== 0 || item.payValue !== 0) && (data.carantine && (item.quarantineEnd || item.quarantineStart)) ? 1 : 0);
    const mainRow = (
      <tr key={item.day}>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.day}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{new Date(item.date).toLocaleDateString('en-GB').replace(/\//g, '.')}</td>
        {item.scadentValue !== 0 || item.payValue !== 0 ?
      <>
      <td className={item.scadentValue === 0 && item.payValue === 0 ? "scadent-noborder" : ""}>{item.scadentValue !== 0 ? item.scadentValue.toFixed(2) : ""}</td>
      <td className={item.payValue === 0 && item.scadentValue === 0 ? "paied-noborder" : ""}>{item.payValue !== 0 ? item.payValue.toFixed(2) : ""}</td></> 
      : 
      <>
      <td colSpan ="2" className={data.carantine && item.quarantineStart || item.quarantineEnd ? 'moved quarantine': ''}>
        {data.carantine ? item.quarantineStart ? <>Inceput stare de urgență <br /></> : "" : ""}
        {data.carantine ? item.quarantineEnd ? <>Sfarsit stare de urgență  <br/></>: "" : ""}
        {item.moved && daysTillStart !== 0 ? <> {`${daysTillStart} zile (${item.movedValue.toFixed(2)})`} <br /></> : ""}
      </td>
      </>
      }
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.duty !== 0 ? item.duty.toFixed(2) : ""}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.contractDuty !== 0 ? item.contractDuty.toFixed(2) : ""}</td>
        {brief ? <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.contractDuty === 0 ? "" : item.daysNumber == 0 ? "": item.daysNumber}</td>:null}
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.contractDuty === 0 ? ""  : item.inflationRate !== 0 ? `${item.inflationRate.toFixed(4)}%` : ""}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.damageAmountPerDay !== 0 ? item.damageAmountPerDay.toFixed(2) : ""}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.totalDamageAmount !== 0 ? item.totalDamageAmount.toFixed(2) : ""}</td>
      </tr>
    );
    rows.push(mainRow);

    if ((item.scadentValue !== 0 || item.payValue !== 0) && item.moved && daysTillStart !== 0 ) {
      const movedRow = (
        <tr key={`${item.day}-moved`}>
          <td colSpan="2" className='moved'>{`${daysTillStart} zile (${item.movedValue.toFixed(2)})`}</td>
        </tr>
      );
      rows.push(movedRow);
    }
    if((item.scadentValue !== 0 || item.payValue !== 0) && data.carantine){
      if(item.quarantineStart){
        const movedRow = (
          <tr key={`${item.day}-moved`}>
            <td colSpan="2" className='moved quarantine'>Inceput stare de urgență</td>
          </tr>
        );
        rows.push(movedRow);
      }
      if(item.quarantineEnd){
        const movedRow = (
          <tr key={`${item.day}-moved`}>
            <td colSpan="2" className='moved quarantine'>Sfarsit stare de urgență</td>
          </tr>
        );
        rows.push(movedRow);
      }
    }

    return rows;
  }) : Array.from({ length: 1 }).map((_, index) => (
    <tr key={index} className="blurred-row" onClick={!authUser ? handleRowClick : handlePaymentClick}>
    <td colSpan={brief ? "10" : "9"} >
    {<span className="message">{authUser ? "Alege unul din abonamente pentru a vizualiza rezultatul" : "Autentifica-te pentru a vizualiza rezultatul"}</span>}
    </td>
</tr>
  ))}
     {authUser &&          <tr className='last-row'>
            <td>Total:</td>
            <td></td>
            <td>{totalScadent.toFixed(2)}</td>
            <td>{totalPaid.toFixed(2)}</td>
            <td>{totalDuty.toFixed(2)}</td>
            <td></td>
            {brief ? <td></td>:null}
            <td></td>
            <td></td>
            <td>{totalDamageAmount.toFixed(2)}</td>
          </tr>}
</tbody>
      </table>
     </div>
    );
  }else  if(data.combinedResults){
    const { combinedResults, totalPenalty, totalPaid, totalScadent, totalDuty, daysTillStart, brief, totalRate,combinedCalcType} = data;
    return (
     <div className='results-content'>
        <div className='table-header'>
        <div className='info'>
        <div className='inline-wrapper'>
  <b>{calcName}</b>
  <FaEdit className='edit-icon hide-on-print' onClick={handleEditClick}/>
  </div>
  <b>Calculul datoriei</b>
            <p>
            <b>Datoria</b> constituie – <b style={!paid ?{ filter: 'blur(5px)' } : null}> {(totalDuty).toFixed(2)} {selectedValue.label} </b> şi a fost calculată pentru perioada 
            <b>
  &nbsp;
  {data.combinedResults[0].date &&
    new Date(data.combinedResults[0].date)
      .toLocaleDateString('en-GB')
      .replace(/\//g, '.')} –{' '}
  {data.penaltyDate &&
    new Date(data.penaltyDate)
      .toLocaleDateString('en-GB')
      .replace(/\//g, '.')}
</b>
,
            cu următoarea succesiune a plăţilor:
            <br/>
            </p>
            <p>
            <ol>
             {(combinedCalcType == 1 ||  combinedCalcType == 3) && <li>Penalităţi, conform contractului,</li>}
              {(combinedCalcType == 2 ||  combinedCalcType == 3) && <li>Dobînda de întîrziere, conform art. 942 alin. 2 Cod civil al RM,</li>}
              <li>Datoria.</li>
            </ol>
            </p>
        </div>
        </div>
       <table id="results-table" className='table combined-results-table'>
       <thead>
        <tr>
          <th rowSpan={2}>Zi</th>
          <th rowSpan={2}>Data</th>
          <th rowSpan={2}>Sume scadente</th>
          <th rowSpan={2}>Sume achitate</th>
          <th rowSpan={2}>Sold</th>
          <th rowSpan={2}>Datorie</th>
          {brief ? <th rowSpan = {2}>Numărul de zile</th> : null}
          {(combinedCalcType == 2 ||  combinedCalcType == 3 )&& <th colSpan={3}>Dobânda</th>}
          {(combinedCalcType == 1 ||  combinedCalcType == 3) && <th colSpan={3}>Penalitate</th>}
        </tr>
        <tr>
         {(combinedCalcType == 2 ||combinedCalcType == 3) &&  <><th>Rata de bază</th>
          <th>{brief ? "Dobânda per/zi" : "Dobânda"}</th>
          <th>Total</th></> }
          {(combinedCalcType == 1 || combinedCalcType == 3) && <>
          <th>Mărime penalitate</th>
          <th>{brief ? "Penalitate per/zi" : "Penalitate"}
          </th>
          <th>Total</th></>}
        </tr>
      </thead>
        <tbody>
  {(authUser && paid) ? combinedResults.flatMap(item => {
    const rows = [];
    const movedRowCount = ((item.scadentValue !== 0 || item.payValue !== 0) && (item.moved && daysTillStart !== 0) ? 1 : 0) + ((item.scadentValue !== 0 || item.payValue !== 0) && (data.carantine && (item.quarantineEnd || item.quarantineStart)) ? 1 : 0);
    const mainRow = (
      <tr key={item.day}>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.day}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{new Date(item.date).toLocaleDateString('en-GB').replace(/\//g, '.')}</td>
        {item.scadentValue !== 0 || item.payValue !== 0 ?
      <>
      <td className={item.scadentValue === 0 && item.payValue === 0 ? "scadent-noborder" : ""}>{item.scadentValue !== 0 ? item.scadentValue.toFixed(2) : ""}</td>
      <td className={item.payValue === 0 && item.scadentValue === 0 ? "paied-noborder" : ""}>{item.payValue !== 0 ? item.payValue.toFixed(2) : ""}</td></> 
      : 
      <>
      <td colSpan ="2" className={data.carantine && item.quarantineStart || item.quarantineEnd ? 'moved quarantine': ''}>
        {data.carantine ? item.quarantineStart ? <>Inceput stare de urgență <br /></> : "" : ""}
        {data.carantine ? item.quarantineEnd ? <>Sfarsit stare de urgență  <br/></>: "" : ""}
        {item.moved && daysTillStart !== 0 ? <> {`${daysTillStart} zile (${item.movedValue.toFixed(2)})`} <br /></> : ""}
      </td>
      </>
      }
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.duty !== 0 ? item.duty.toFixed(2) : ""}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.contractDuty !== 0 ? item.contractDuty.toFixed(2) : ""}</td>
        {brief ? <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.contractDuty === 0 ? "" : item.daysNumber == 0 ? "": item.daysNumber}</td>:null}
        {(combinedCalcType == 2 || combinedCalcType == 3) && 
        <>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{ item.contractDuty === 0 ? ""  :  item.baseRate !== 0 ? `${(item.baseRate.toFixed(4) * 100).toFixed(2)}%` : ""}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.interestRate !== 0 ? item.interestRate.toFixed(2) : ""}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.totalInterestRate !== 0 ? item.totalInterestRate.toFixed(2) : ""}</td>
        </>}
       {(combinedCalcType == 1 ||  combinedCalcType == 3) &&  <> 
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.contractDuty === 0 ? "" : item.penaltyType ? item.penaltyValue !== 0 ? item.penaltyValue.toFixed(2) : "" : item.penaltyValue !== 0 ? (item.penaltyValue * 100).toFixed(2) +"%" : ""}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.penaltyPerDay !== 0 ? item.penaltyPerDay.toFixed(2) : ""}</td>
        <td rowSpan={(movedRowCount + ((item.scadentValue !== 0 || item.payValue !== 0))) ? movedRowCount + 1 : 1}>{item.totalPenalty !== 0 ? item.totalPenalty.toFixed(2) : ""}</td></>}
      </tr>
    );
    rows.push(mainRow);
    if ((item.scadentValue !== 0 || item.payValue !== 0) && item.moved && daysTillStart !== 0 ) {
      const movedRow = (
        <tr key={`${item.day}-moved`}>
          <td colSpan="2" className='moved'>{`${daysTillStart} zile (${item.movedValue.toFixed(2)})`}</td>
        </tr>
      );
      rows.push(movedRow);
    }

    if((item.scadentValue !== 0 || item.payValue !== 0) && data.carantine){
      if(item.quarantineStart){
        const movedRow = (
          <tr key={`${item.day}-moved`}>
            <td colSpan="2" className='moved quarantine'>Inceput stare de urgență</td>
          </tr>
        );
        rows.push(movedRow);
      }
      if(item.quarantineEnd){
        const movedRow = (
          <tr key={`${item.day}-moved`}>
            <td colSpan="2" className='moved quarantine'>Sfarsit stare de urgență</td>
          </tr>
        );
        rows.push(movedRow);
      }
    }

    return rows;
  }) 
  : Array.from({ length: 1 }).map((_, index) => (
    <tr key={index} className="blurred-row" onClick={!authUser ? handleRowClick : handlePaymentClick}>
    <td colSpan={brief ? "10" : "9"} >
    {<span className="message">{authUser ? "Alege unul din abonamente pentru a vizualiza rezultatul" : "Autentifica-te pentru a vizualiza rezultatul"}</span>}
    </td>
</tr>
  ))}
 {authUser &&   <tr className='last-row'>
            <td>Total:</td>
            <td></td>
            <td>{totalScadent.toFixed(2)}</td>
            <td>{totalPaid.toFixed(2)}</td>
            <td></td>
            <td>{totalDuty.toFixed(2)}</td>
            {brief ? <td></td>:null}
            {(combinedCalcType == 2 ||  combinedCalcType == 3) && <>
            <td></td>
            <td></td>
            <td>{totalRate.toFixed(2)}</td></>}
            {(combinedCalcType == 1 ||  combinedCalcType == 3) && <>
            <td></td>
            <td></td>
            <td>{totalPenalty.toFixed(2)}</td></>}
          </tr>}
</tbody>
      </table>
     </div>
    );
  }
}
